import { apolloClient } from '@eva-pacs/client';
import { useSessionStore } from '~/src/store/sessionStore';
import { useAuthContext } from '~/src/contexts/authContext';

export const useLogout = () => {
  const setCurrentOrganizationId = useSessionStore((state) => state.setCurrentOrganizationId);
  const { logout: logoutService } = useAuthContext();

  const logout = async () => {
    try {
      await logoutService();
    } finally {
      setCurrentOrganizationId('');
      apolloClient.clearStore();
    }
  };
  return { logout };
};
