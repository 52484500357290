import shallow from 'zustand/shallow';
import { ExternalLibUrls } from '../constants/libs';
import type { ExternalLibIds } from '../constants/libs';
import { useLibManagerStore } from '../store/libManagerStore';
import { loadExternalScript } from './headService';

export const useLibManager = () => {
  const [addLibLoaded, addLibPromise, removeLibPromise] = useLibManagerStore(
    (store) => [store.addLibLoaded, store.addLibPromise, store.removeLibPromise],
    shallow,
  );

  const load = (id: ExternalLibIds): Promise<ExternalLibIds> => {
    // if already loaded return it right away
    const islibraryLoaded = useLibManagerStore.getState().libsLoaded[id];
    if (islibraryLoaded) return new Promise<ExternalLibIds>((resolve) => resolve(id));

    // if we are currently loading this lib, return its promise
    const currentPromise = useLibManagerStore.getState().promises[id];
    if (currentPromise) return currentPromise;

    // handle success and failure of the Lib request load
    const promise = new Promise<ExternalLibIds>((resolve, reject) => {
      try {
        loadExternalScript(id, ExternalLibUrls[id], () => {
          addLibLoaded(id);
          resolve(id);
        });
      } catch {
        return reject('Error Loading this library');
      }
    });
    promise.then(removeLibPromise);
    addLibPromise(id, promise);
    return promise;
  };

  return { load };
};
