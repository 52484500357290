import { MODALITY_TYPES } from '@eva-pacs/core';
import { MG_VARIANTS } from '~/constants';
import { useSeriesStore } from '~/src/store';
import { SeriesThumbnailsWithMetaType } from '~/utils/thumbnails';
import { findSerieForProtocol } from './findSeriesForProtocol';
import { HangingProtocol, SerieLocation } from './hangingProtocolTypes';

const HANGING_MG_LAYOUT = { row: 0, col: 3 };
const HANGING_MG_LAYOUT_MULTI_MONITOR = { row: 0, col: 1 };

const INVALID_PROTOCOL: Array<HangingProtocol> = [{ serieLocations: [], layout: HANGING_MG_LAYOUT, isValid: false }];

/**
 * Generates a hanging protocol for mammography (MG) based on the provided series metadata.
 *
 * @param series - An array of series metadata (`SeriesThumbnailsWithMetaType`)
 *                 from which the mammography protocol will be constructed.
 *                 Each series is expected to include the necessary metadata to identify specific MG variants.
 *
 * @returns An object of type `HangingProtocol` that includes:
 *          - `serieLocations`: An array specifying the layout and series assigned to each viewport position.
 *          - `layout`: The layout configuration for MG studies.
 *          - `isValid`: A boolean indicating whether all series required for the protocol were successfully found.
 *
 * @remarks
 * the images are displayed in the following order:
 * [CCR, CCL, MLOR, MLOL]
 * In multi-monitor setup, the images are displayed in the following order:
 * [CCR, CCL],[MLOR, MLOL]
 * If any of the required series are missing, `isValid` will return `false`.
 */
const getProtocolForMG = (series: Array<SeriesThumbnailsWithMetaType>): Array<HangingProtocol> => {
  const serieLocations: Array<SerieLocation> = [
    {
      viewportPositionIndex: 0,
      viewportImageIndex: 0, // First image
      serie: findSerieForProtocol(series, MG_VARIANTS.CCR),
    },
    {
      viewportPositionIndex: 1,
      viewportImageIndex: 0, // First image
      serie: findSerieForProtocol(series, MG_VARIANTS.CCL),
    },
    {
      viewportPositionIndex: 2,
      viewportImageIndex: 0, // First image
      serie: findSerieForProtocol(series, MG_VARIANTS.MLOR),
    },
    {
      viewportPositionIndex: 3,
      viewportImageIndex: 0, // First image
      serie: findSerieForProtocol(series, MG_VARIANTS.MLOL),
    },
  ];

  return [{ serieLocations, layout: HANGING_MG_LAYOUT, isValid: serieLocations.every((serie) => !!serie.serie) }];
};

/**
 * Generates a hanging protocol for mammography (MG) based on the provided series metadata for multiple windows.
 *
 * @param series - An array of series metadata (`SeriesThumbnailsWithMetaType`)
 *                 from which the mammography protocol will be constructed.
 *                 Each series is expected to include the necessary metadata to identify specific MG variants.
 *
 * @returns An object of type `HangingProtocol` that includes:
 *          - `serieLocations`: An array specifying the layout and series assigned to each viewport position.
 *          - `layout`: The layout configuration for MG studies.
 *          - `isValid`: A boolean indicating whether all series required for the protocol were successfully found.
 *
 * @remarks
 * the images are displayed in the following order:
 *  Monitor 1, Monitor 2
 * [MLOR, MLOL], [CCR, CCL]
 * If any of the required series are missing, `isValid` will return `false`.
 */
const getProtocolForMGMultipleMonitors = (series: Array<SeriesThumbnailsWithMetaType>): Array<HangingProtocol> => {
  const mainWindowSerieLocation: Array<SerieLocation> = [
    {
      viewportPositionIndex: 0,
      viewportImageIndex: 0, // First image
      serie: findSerieForProtocol(series, MG_VARIANTS.CCR),
    },
    {
      viewportPositionIndex: 1,
      viewportImageIndex: 0, // First image
      serie: findSerieForProtocol(series, MG_VARIANTS.CCL),
    },
  ];

  const secondaryWindowSerieLocation = [
    {
      viewportPositionIndex: 0,
      viewportImageIndex: 0, // First image
      serie: findSerieForProtocol(series, MG_VARIANTS.MLOR),
    },
    {
      viewportPositionIndex: 1,
      viewportImageIndex: 0, // First image
      serie: findSerieForProtocol(series, MG_VARIANTS.MLOL),
    },
  ];

  return [
    {
      serieLocations: mainWindowSerieLocation,
      layout: HANGING_MG_LAYOUT_MULTI_MONITOR,
      isValid: mainWindowSerieLocation.every((serie) => !!serie.serie),
    },
    {
      serieLocations: secondaryWindowSerieLocation,
      layout: HANGING_MG_LAYOUT_MULTI_MONITOR,
      isValid: secondaryWindowSerieLocation.every((serie) => !!serie.serie),
    },
  ];
};

/**
 * Retrieves the hanging protocol series for a specified modality and monitor setup.
 *
 * This function determines the appropriate hanging protocol series based on the
 * modality type, whether multiple monitors are used, and the provided series data.
 * For mammography (`MODALITY_TYPES.MG`), it selects a protocol tailored to single
 * or multiple monitors. For other modalities, an invalid protocol is returned.
 *
 * @param {Array<SeriesThumbnailsWithMetaType>} series -
 *   An array of series objects containing metadata and thumbnails for the studies.
 * @param {MODALITY_TYPES} modality -
 *   The modality type (e.g., MG for mammography) as defined by `MODALITY_TYPES`.
 * @param {boolean} multiMonitor -
 *   A flag indicating whether multiple monitors are being used.
 *
 * @returns {Array<HangingProtocol>}
 *   An array of hanging protocol configurations for the specified modality and monitor setup.
 *   If the modality is unsupported, it returns `INVALID_PROTOCOL`.
 *
 */
export const getHangingProtocolSeriesForModality = (
  series: Array<SeriesThumbnailsWithMetaType>,
  multiMonitor: boolean,
): Array<HangingProtocol> => {
  const getSerieById = useSeriesStore.getState().getSerieById;
  // validate if exists a serie with modality MG
  if (series.find((serie) => getSerieById(serie.id)?.modalityType === MODALITY_TYPES.MG)) {
    if (multiMonitor) return getProtocolForMGMultipleMonitors(series);
    return getProtocolForMG(series);
  }
  return INVALID_PROTOCOL;
};
