import { LATERALITY_TYPE, MG_VARIANTS, VIEW_POSITION } from '~/constants';

/**
 * Determines the mammography variant based on series tag information.
 *
 * @param serieTagsInfo - An object containing tag values as a JSON string, which includes
 *                        DICOM metadata fields such as `Laterality`, `ViewPosition`, and `ImageLaterality`.
 * @returns An `MG_VARIANTS` value representing the identified mammography variant
 *          (e.g., `CCR`, `MLOL`, etc.), or `null` if no variant is matched.
 *
 * @example
 * const serieTagsInfo = JSON.stringify({
 *     Laterality: "R",
 *     ViewPosition: "CC",
 *     ImageLaterality: "R"
 *   })
 * ;
 * const variant = getMGVariant(serieTagsInfo);
 * console.log(variant); // MG_VARIANTS.CCR
 *
 * @remarks
 * - The function relies on predefined constants `LATERALITY_TYPE` and `VIEW_POSITION`
 *   to match lateralities and view positions to mammography variants.
 * - If no valid combination of lateralities and view position is found, the function returns `null`.
 * - The function assumes `serieTagsInfo.tagValues` is a valid JSON string. If not, parsing will fail.
 *
 * @dependencies
 * - Requires `MG_VARIANTS`, `LATERALITY_TYPE`, and `VIEW_POSITION` enums or constants.
 */
export const getMGVariant = (tagValues: string): MG_VARIANTS | null => {
  let tagValuesObj: Record<string, string> = {};
  try {
    tagValuesObj = JSON.parse(tagValues);
  } catch (error) {
    tagValuesObj = {};
  }
  const { Laterality, ViewPosition, ImageLaterality } = tagValuesObj;
  const instanceLateralities = [ImageLaterality, Laterality];

  if (instanceLateralities.includes(LATERALITY_TYPE.R) && ViewPosition === VIEW_POSITION.CC) {
    return MG_VARIANTS.CCR;
  } else if (instanceLateralities.includes(LATERALITY_TYPE.L) && ViewPosition === VIEW_POSITION.MLO) {
    return MG_VARIANTS.MLOL;
  } else if (instanceLateralities.includes(LATERALITY_TYPE.L) && ViewPosition === VIEW_POSITION.CC) {
    return MG_VARIANTS.CCL;
  } else if (instanceLateralities.includes(LATERALITY_TYPE.R) && ViewPosition === VIEW_POSITION.MLO) {
    return MG_VARIANTS.MLOR;
  }
  return null;
};
