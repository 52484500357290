/**
 * Enumerates the different families of user preferences.
 */
export enum UserPreferenceFamily {
  FILTER = 'FILTER',
  SCREENS = 'SCREENS',
  LIST_TYPE = 'LIST_TYPE',
  SHORTCUTS = 'SHORTCUTS',
  STUDY_LIST = 'STUDY_LIST',
  FILTER_ORDERS = 'FILTER_ORDERS',
  FILTER_APPOINTMENTS = 'FILTER_APPOINTMENTS',
}

/**
 * Represents a user preference configuration.
 */
export interface UserPreference {
  value: string;
  readonly id: string;
  readonly key: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly family: UserPreferenceFamily;
}
