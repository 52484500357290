import { useEffect, useState } from 'react';
import { VIEWER_PATH } from '@eva-pacs/core';
import { useFeatureFlagsStore, useThumbnailSeriesStore } from '~/src/store';
import { FeatureFlags, SYNC_EVENT_KEY } from '~/constants';
import { useBrowserWindowListeners, useSyncActionBrowserWindowsCommunication } from '~/hooks';
import { SeriesThumbnailsWithMetaType } from '~/utils/thumbnails';
import { useSetHangingProtocol } from './useSetHangingProtocol';
import { getHangingProtocolSeriesForModality } from '~/utils/hangingProtocol';

export type SetLayoutFunction = (
  layout: { row: number; col: number },
  onlyLocalLayoutChange?: boolean,
  onlySetLayout?: boolean,
) => void;

const DELAY_TO_PROPAGATE_PROTOCOL = 100;
const TIMEOUT_TO_VERIFY_ACTIVE_WINDOWS = 200;

export const useHangingProtocol = (setGridLayout: SetLayoutFunction) => {
  const hasFlag = useFeatureFlagsStore((state) => state.hasFlag);
  const thumbnailsList = useThumbnailSeriesStore((store) => store.thumbailsList);

  const [isProtocolEvaluated, setIsProtocolEvaluated] = useState<boolean>(false);
  const { getActiveWindows } = useBrowserWindowListeners();
  const isHangingProtocolEnabled = hasFlag(FeatureFlags.EnableHangingProtocol);
  const { notifyEvent, event: incomingWindowNotification } = useSyncActionBrowserWindowsCommunication();

  const setProtocolInWindow = useSetHangingProtocol();

  useEffect(() => {
    if (incomingWindowNotification?.scope === SYNC_EVENT_KEY.APPLY_HANGING_PROTOCOL) {
      const protocol = JSON.parse(incomingWindowNotification?.data);
      setProtocolInWindow(protocol, setGridLayout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingWindowNotification]);

  const isTheMainWindow = () => {
    const mainViewportWindowIndex = 0;
    /*
     * If the tab has no name, it is because it was not automatically opened on multiple monitors, so we handle it as a single monitor.
     */
    if (!window.name) return true;
    return `${VIEWER_PATH}_${mainViewportWindowIndex}` === window.name;
  };

  const applyHangingProtocol = async () => {
    const [firstSerieThumbnail] = thumbnailsList;

    if (!isHangingProtocolEnabled || !firstSerieThumbnail || isProtocolEvaluated) return;
    const activeWindows = await getActiveWindows(TIMEOUT_TO_VERIFY_ACTIVE_WINDOWS);

    const multiMonitor = activeWindows.length >= 1;
    const mainWindow = isTheMainWindow();
    if (!mainWindow) return;

    const [protocol, secondProtocol] = getHangingProtocolSeriesForModality(
      (thumbnailsList as unknown) as Array<SeriesThumbnailsWithMetaType>,
      multiMonitor,
    );

    const { isValid } = protocol;
    setIsProtocolEvaluated(true);
    if (!isValid) return;
    setProtocolInWindow(protocol, setGridLayout);
    if (!secondProtocol?.isValid) return;
    setTimeout(() => {
      if (!multiMonitor) return;
      notifyEvent({
        scope: SYNC_EVENT_KEY.APPLY_HANGING_PROTOCOL,
        data: JSON.stringify(secondProtocol),
      });
    }, DELAY_TO_PROPAGATE_PROTOCOL);
  };

  useEffect(() => {
    applyHangingProtocol();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbnailsList, isProtocolEvaluated, isHangingProtocolEnabled]);
};
