import { MODALITY_TYPES } from '@eva-pacs/core';
import { MG_VARIANTS } from '~/constants';
import type { SeriesThumbnailsType } from '@eva-pacs/client';
import { createMetaSerieId } from './metaSerieId';
import { getMGVariant } from './getMGVariant';

interface MetaSerieItem {
  id: string;
  instanceCount: number;
  meta: {
    originSerieId: string;
    metaSerieVariant: MG_VARIANTS; //In the future, this should be for another type of studies
    instanceIds: Array<string>;
    metaDescription: string;
    isASubSerie?: boolean; // This is a flag to know if the serie is a subserie produced by a split
  };
}

export const isASplittableSerie = (serie: SeriesThumbnailsType) => {
  // For now, only MG series are splittable
  if (serie.modality !== MODALITY_TYPES.MG) return false;
  return serie.series.dicomTags && serie.series.dicomTags?.length > 0;
};

export const splitMGSerie = (serie: SeriesThumbnailsType) => {
  const { series } = serie;

  if (!series.dicomTags || !series.dicomTags?.length) {
    return [];
  }

  const CCLImages: MetaSerieItem = {
    instanceCount: 0,
    id: '',
    meta: {
      originSerieId: serie.id,
      metaDescription: '',
      metaSerieVariant: MG_VARIANTS.CCL,
      instanceIds: [],
    },
  };
  const MLOLImages: MetaSerieItem = {
    instanceCount: 0,
    id: '',
    meta: {
      originSerieId: serie.id,
      metaDescription: '',
      metaSerieVariant: MG_VARIANTS.MLOL,
      instanceIds: [],
    },
  };
  const CCRImages: MetaSerieItem = {
    instanceCount: 0,
    id: '',
    meta: {
      originSerieId: serie.id,
      metaDescription: '',
      metaSerieVariant: MG_VARIANTS.CCR,
      instanceIds: [],
    },
  };
  const MLORImages: MetaSerieItem = {
    instanceCount: 0,
    id: '',
    meta: {
      originSerieId: serie.id,
      metaDescription: '',
      metaSerieVariant: MG_VARIANTS.MLOR,
      instanceIds: [],
    },
  };
  const OtherImages: MetaSerieItem = {
    instanceCount: 0,
    id: '',
    meta: {
      originSerieId: serie.id,
      metaDescription: '',
      metaSerieVariant: MG_VARIANTS.CCL,
      instanceIds: [],
    },
  };

  series.dicomTags.forEach((serieTagsInfo) => {
    if (!serieTagsInfo?.tagValues) return;
    const mgVariant = getMGVariant(serieTagsInfo.tagValues || '');
    switch (mgVariant) {
      case MG_VARIANTS.CCR:
        CCRImages.meta.instanceIds.push(serieTagsInfo.instance);
        break;
      case MG_VARIANTS.CCL:
        CCLImages.meta.instanceIds.push(serieTagsInfo.instance);
        break;
      case MG_VARIANTS.MLOR:
        MLORImages.meta.instanceIds.push(serieTagsInfo.instance);
        break;
      case MG_VARIANTS.MLOL:
        MLOLImages.meta.instanceIds.push(serieTagsInfo.instance);
        break;
      default:
        OtherImages.meta.instanceIds.push(serieTagsInfo.instance);
        break;
    }
  });

  const metaSeries = [CCRImages, CCLImages, MLORImages, MLOLImages, OtherImages].filter(
    (series) => series.meta.instanceIds.length > 0,
  );

  metaSeries.forEach((metaSerie, index) => {
    metaSerie.instanceCount = metaSerie.meta.instanceIds.length;
    metaSerie.meta.metaDescription = metaSerie.meta.metaSerieVariant || '';
    metaSerie.id = createMetaSerieId(serie.id, index);
  });
  return metaSeries;
};

export const splitMetaSeries = (serie) => {
  if (serie.modality === MODALITY_TYPES.MG) {
    return splitMGSerie(serie);
  }
  return [];
};
