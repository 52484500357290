import { useEffect, useRef } from 'react';
export var useAutoFocus = function () {
    var containerRef = useRef(null);
    useEffect(function () {
        if (containerRef.current) {
            var focusableElements = containerRef.current.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
            if (focusableElements.length > 0) {
                focusableElements[0].focus();
            }
        }
    }, []);
    return containerRef;
};
