import * as dateFnsLocales from 'date-fns/locale';
import { utcToZonedTime, formatInTimeZone } from 'date-fns-tz';
import { format, formatDistanceStrict, formatISO, isSameYear, parseISO } from 'date-fns';
import { capitalizeFirstLetter } from './capitalizeFirstLetter';
import { areTimeZonesRelated } from './timezone';
var locales = {
    pt: dateFnsLocales.ptBR,
    en: dateFnsLocales.enUS,
    es: dateFnsLocales.es,
};
var getDateFnsLocale = function (locale) { var _a; return (_a = locales[locale]) !== null && _a !== void 0 ? _a : locales.es; };
export default getDateFnsLocale;
export var DATE_FORMAT = {
    DD_MMM_YYYY: 'dd MMM yyyy',
    DD_MM_YYYY_HH_MM_AA: 'dd/MM/yyyy, h:mm aa',
    DD_MMM_YYYY_HH_MM_AA: 'dd MMM yyyy, h:mm aa',
};
/**
 * @param dateString date with string type
 * @param timezone timezone to format date
 * @param locale language to format date
 * @returns date with format dd MMM yyyy
 * {example}:
 * input: 2021-07-21T23:37:43.747788+00:00
 * output: 21 Jul 2021
 */
export var formatStringDate = function (dateString, locale, timezone) {
    if (locale === void 0) { locale = 'es'; }
    if (timezone) {
        return formatInTimeZone(dateString, timezone, DATE_FORMAT.DD_MMM_YYYY, { locale: getDateFnsLocale(locale) });
    }
    return format(new Date(dateString), DATE_FORMAT.DD_MMM_YYYY, { locale: getDateFnsLocale(locale) });
};
/**
 * @param dateString backend formatted birth date with string type
 * @param timezone timezone to format date
 * @param locale language to format date
 * @returns date with format dd MMM yyyy
 * {example}:
 * input: 2021-07-21
 * output: 21 Jul 2021
 */
export var formatStringBirthDate = function (dateString, locale, timezone) {
    if (locale === void 0) { locale = 'es'; }
    if (!(dateString === null || dateString === void 0 ? void 0 : dateString.length))
        return '';
    var formatedDate = convertEdenDateStringToDate(dateString);
    if (timezone)
        return formatInTimeZone(formatedDate, timezone, DATE_FORMAT.DD_MMM_YYYY, { locale: getDateFnsLocale(locale) });
    return format(formatedDate, DATE_FORMAT.DD_MMM_YYYY, { locale: getDateFnsLocale(locale) });
};
export var parseFromDate = function (date) {
    if (!date)
        return;
    var parseDate = "".concat(date, "T00:00:00");
    return formatISODateTime(parseDate);
};
export var parseToDate = function (date) {
    if (!date)
        return;
    var parseDate = "".concat(date, "T23:59:59");
    return formatISODateTime(parseDate);
};
/**
 * @param dateTimeString date time with string type
 * @returns date with format ISO
 * {example}:
 * input: 2022-03-25T23:59:59
 * output: 2021-01-12T02:57:09+01:00
 */
export var formatISODateTime = function (dateTimeString) {
    return formatISO(new Date(dateTimeString));
};
/**
 * @param dateTimeString date time with string type
 * @returns date with format dd/MM/yyyy, HH:mm
 * {example}:
 * input: 2021-07-21T23:37:43.747788+00:00
 * output: 21/07/2021, 18:37h
 */
export var formatStringDateTime = function (dateTimeString) {
    if (!dateTimeString)
        return;
    return format(new Date(dateTimeString), "dd/MM/yyyy, HH:mm'h'");
};
/**
 * @param dateTimeString date time with string type
 * @returns date with format dd/MM/yyyy, HH:mm:ss
 * {example}:
 * input: 2021-07-21T23:37:43.747788+00:00
 * output: 21/07/2021, 18:37h
 */
export var formatStringDateFullTime = function (dateTimeString) {
    return format(new Date(dateTimeString), 'dd/MM/yyyy, HH:mm:ss');
};
/**
 * @param formatStringNoTimeDate date with string type
 * @returns date with format dd/MM/yyyy, HH:mm
 * {example}:
 * input: 2021-07-21T23:37:43.747788+00:00
 * output: 21/07/2021
 */
export var formatStringNoTimeDate = function (dateString) {
    if (dateString === void 0) { dateString = ''; }
    if (!dateString)
        return;
    return format(new Date(dateString), 'dd/MM/yyyy');
};
/**
 * @param formatStringNoTimeDate date with string type
 * @returns date with format dd/MM/yyyy
 * {example}:
 * input: 2021-07-21
 * output: 21/07/2021
 */
export var parseBirthDate = function (dateString) {
    if (dateString === void 0) { dateString = ''; }
    if (!dateString)
        return;
    return dateString.split('-').reverse().join('/');
};
/**
 * @param formatStringKebabCaseDate date with string type
 * @returns date with format yyyy-mm-dd
 * {example}:
 * input: 2021-07-21T23:37:43.747788+00:00
 * output: 2021-07-21
 */
export var formatStringKebabCaseDate = function (dateString) {
    if (dateString === void 0) { dateString = ''; }
    if (!dateString)
        return;
    return format(new Date(dateString), 'yyyy-MM-dd');
};
/**
 * @param dateString date with string type
 * @returns date with distance 3 days
 * {example}:
 * input: 2021-07-21T23:37:43.747788+00:00
 * output: 3 days
 */
export var formatStringDistance = function (dateString, locale) {
    return capitalizeFirstLetter(formatDistanceStrict(new Date(dateString), new Date(), { locale: getDateFnsLocale(locale), addSuffix: true }));
};
/**
 * @param date the date as string.
 * @returns date
 * {example}:
 * input: Date
 * output: 23/Abr/2022
 */
export var formatLocalizedDateString = function (dateString, locale) {
    if (!dateString)
        return;
    var date = new Date(dateString);
    var utcDate = formatDateToUTC(date);
    return formatStringDate(utcDate.toISOString(), locale);
};
/**
 * @param date the date as Date.
 * @returns date in a UTC format
 * {example}:
 * input: Date object ('1991-12-17') // Mon Dec 16 1991 18:00:00 GMT-0600 (hora estándar central)
 * output: Date object ('1991-12-17') // Tue Dec 17 1991 00:00:00 GMT-0600 (hora estándar central)
 */
export var formatDateToUTC = function (date) {
    var currentTimeOffset = date.getTimezoneOffset() * 60 * 1000;
    return new Date(date.getTime() + currentTimeOffset);
};
/**
 * @param dateString the date as string.
 * @returns date with format dd MMM yyyy, HH:mm a
 * {example}:
 * input: 2021-07-21T23:37:43.747788+00:00
 * output: 21 Jul 2021, 18:37 PM
 */
export var formatStringDateWithTime = function (dateString, locale) {
    return format(new Date(dateString), 'dd MMM yyyy, HH:mm a', { locale: getDateFnsLocale(locale) });
};
/**
 * @param date the date from where generate the dateString
 * @returns date
 * {example}:
 * input: Date
 * output: 23 abr 2022
 */
export var formatDateToLocalizedString = function (date, language) {
    if (language === void 0) { language = 'es'; }
    if (!date)
        return;
    return format(date, 'd MMM yyyy', { locale: getDateFnsLocale(language) });
};
/**
 * @param date the date from where to generate the formatted time
 * @returns string
 * {example}:
 * input: Date
 * output: 10:00 am
 */
export var formatDateToStringTime = function (date) {
    if (!date)
        return;
    return format(date, 'h:mmaaa');
};
/**
 * @param date the date from where to generate the dateString
 * @returns date
 * {example}:
 * input: Date
 * output: 2021-07-21
 */
export var formatDateToStringKebabCaseDate = function (date) {
    if (!date)
        return;
    return format(date, 'yyyy-MM-dd');
};
/**
 * Retrieve an object with formatted relevant date parts
 * @param dateString
 * @param timezone
 * @returns { date: Date, kebabCaseDate: string, time: string}
 */
export var formatDateParts = function (dateString, timezone) {
    var date = new Date(dateString);
    if (timezone)
        date = utcToZonedTime(dateString, timezone);
    return {
        date: date,
        kebabCaseDate: formatDateToStringKebabCaseDate(date),
        time: formatDateToStringTime(date),
    };
};
/**
 * A function to format a time range
 * @param firstDate The first date of the range
 * @param secondDate The second date of the range
 * @param timezone The timezone to use
 * @returns string
 */
export var formattedRangeTime = function (firstDate, secondDate, timezone) {
    var formattedFirstDate = formatDateParts(firstDate, timezone);
    if (!secondDate)
        return formattedFirstDate.time;
    var formattedSecondDate = formatDateParts(secondDate, timezone);
    var areDatesInSameDay = formattedFirstDate.kebabCaseDate === formattedSecondDate.kebabCaseDate;
    var timeRange = [formattedFirstDate.time, formattedSecondDate.time].join('-');
    if (!areDatesInSameDay)
        return "".concat(timeRange, " +1"); // `+1` means that the second date occurs on the next day
    return timeRange;
};
/**
 * A function to parse a date time string to a date object
 * @param dateTimeString The date time string to parse
 * @returns Date
 */
export var parseDateTime = function (dateTimeString) {
    if (!dateTimeString || isNaN(new Date(dateTimeString).getTime()))
        return;
    return new Date(dateTimeString).getTime();
};
/**
 * A function to parse a date time string to a date object
 * @param dateString The date string to parse
 * @param timezone The timezone to use
 * @param language The language to use
 * @returns Date
 * {example}:
 * input: 2021-07-21T23:37:43.747788+00:00, 'America/Mexico_City'
 * output: 1626896263747
 */
export var parsedDateTimeWithTimezone = function (dateString, timezone, language) {
    if (timezone === void 0) { timezone = 'UTC'; }
    if (language === void 0) { language = 'es'; }
    if (!dateString)
        return;
    return parseDateTime(formatInTimeZone(dateString, timezone, 'yyyy-MM-dd HH:mm:ss', { locale: getDateFnsLocale(language) }));
};
/**
 * A function to compare timezones
 * @param dateString The date to compare
 * @param targetTimezone The timezone to compare
 * @param language The language to use
 * @returns { gmtoffset: number, isSameTimezone: boolean }
 * {example}:
 * input: 2021-07-21T23:37:43.747788+00:00, 'America/Mexico_City'
 * output: { gmtoffset: -5, isSameTimezone: false }
 */
export var compareTimezones = function (dateString, targetTimezone, language) {
    if (language === void 0) { language = 'es'; }
    var currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (!currentTimezone)
        return { isSameTimezone: true };
    if (areTimeZonesRelated(currentTimezone, targetTimezone)) {
        return { isSameTimezone: true };
    }
    var utcTime = parsedDateTimeWithTimezone(dateString, 'UTC', language);
    var zoneTime = parsedDateTimeWithTimezone(dateString, targetTimezone, language);
    var userTime = parsedDateTimeWithTimezone(dateString, currentTimezone, language);
    var gmtoffset = zoneTime && utcTime ? Math.round((zoneTime - utcTime) / (1000 * 60 * 60)) : null;
    var gmtoffsetUser = userTime && utcTime ? Math.round((userTime - utcTime) / (1000 * 60 * 60)) : null;
    return {
        gmtoffset: gmtoffset,
        gmtoffsetUser: gmtoffsetUser,
        isSameTimezone: false,
    };
};
/**
 * A function to format a date time string to a date object
 * @param dateTimeString The date time string to format
 * @param timezone The timezone to use
 * @param format The format to use
 * @param language The language to use
 * @returns string
 * {example}:
 * input: 2021-07-21T23:37:43.747788+00:00, 'America/Mexico_City'
 * output: '21/07/2021, 5:37 PM'
 */
export var formatDateTimeString = function (dateString, timezone, locale) {
    if (locale === void 0) { locale = 'es'; }
    if (!dateString || !timezone)
        return;
    return formatInTimeZone(dateString, timezone, DATE_FORMAT.DD_MMM_YYYY_HH_MM_AA, { locale: getDateFnsLocale(locale) });
};
/**
 * A function that determines if a date string is older than 30 days
 * @param date The date string to calculate the difference
 * @returns boolean
 */
export var isOlderThan30Days = function (givenDate) {
    var MAX_PERMITTED_DAYS = 30;
    var MILLISECONDS_IN_A_DAY = 1000 * 3600 * 24;
    var currentDate = new Date();
    var differenceInTime = currentDate.getTime() - givenDate.getTime();
    var differenceInDays = differenceInTime / MILLISECONDS_IN_A_DAY;
    return differenceInDays > MAX_PERMITTED_DAYS;
};
var convertEdenDateStringToDate = function (dateString) {
    if (!dateString)
        return new Date();
    var year = parseInt(dateString.slice(0, 4));
    var month = dateString.slice(5, 7);
    var monthIndex = parseInt(month) - 1;
    var day = parseInt(dateString.slice(8, 10));
    var date = new Date(year, monthIndex, day);
    return date;
};
/**
 * A function to format date range
 * @param startDate The start date of the range
 * @param endDate The end date of the range
 * @returns string
 * {example}:
 * input: 2021-07-21, 2021-07-23
 * output: 21 Jul -> 23 Jul, 2021
 */
export var formatDateRange = function (startDate, endDate, locale) {
    if (locale === void 0) { locale = 'es'; }
    if (!startDate && !endDate)
        return '';
    var formatDate = function (date) { return format(parseISO(date), 'd MMMM, yyyy', { locale: getDateFnsLocale(locale) }); };
    if (startDate && endDate) {
        var start = parseISO(startDate);
        var end = parseISO(endDate);
        if (isSameYear(start, end)) {
            return "".concat(format(start, 'd MMMM', { locale: getDateFnsLocale(locale) }), " - ").concat(format(end, 'd MMMM, yyyy', {
                locale: getDateFnsLocale(locale),
            }));
        }
        return "".concat(formatDate(startDate), " - ").concat(formatDate(endDate));
    }
    if (startDate)
        return formatDate(startDate);
    if (endDate)
        return formatDate(endDate);
    return '';
};
/**
 * A function to format date range
 * @param startDate The start date of the range
 * @param endDate The end date of the range
 * @returns string
 * {example}:
 * input: 2021-07-21, 2021-07-23
 * output: Date object ('2021-07-21') // Wed Jul 21 2021 00:00:00 GMT-0500 (hora estándar central)
 */
export var formatStringDateToUTC = function (dateString) {
    if (!dateString)
        return null;
    var date = new Date(dateString);
    if (isNaN(date.getTime()))
        return null;
    return formatDateToUTC(date);
};
