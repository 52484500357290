import produce from 'immer';
import create, { SetState } from 'zustand';

import { PARAMS_KEYS } from '../hooks/useSearchParams';
import { StudyFilterFields, StudyFilterName } from '../types/Filter';
import { UserPreference, UserPreferenceFamily } from '../types/UserPreference';
import { OrganizationSpecificFilterAPIKeys } from '../constants/organizationSpecificFields';

export const immer = (config) => (set, get) => config((fn) => set(produce(fn)), get);

/**
 * Filter store state and actions to manage filters and searchBy
 * @typedef {Object} FilterStoreState
 * @property {string | null} searchBy - Search by value
 * @property {Array<UserPreference>} filters - Filters
 * @property {Partial<StudyFilterFields>} filterFields - Filter fields
 * @property {Partial<StudyFilterFields>} organizationSpecificFilterFields - Organization specific filter fields
 * @property {(searchBy: string) => void} setSearchBy - Set search by value
 * @property {(filters: Array<UserPreference>) => void} setFilters - Set filters
 * @property {(filterKey: string) => void} deleteFilter - Delete filter
 * @type {FilterStoreState}
 * @returns {FilterStoreState}
 */
export interface FilterStoreState {
  searchBy: string | null;
  filters: Array<UserPreference>;
  filterFields: Partial<StudyFilterFields>;
  organizationSpecificFilterFields: Partial<StudyFilterFields>;
  setSearchBy: (searchBy: string) => void;
  setFilters: (filters: Array<UserPreference>) => void;
  deleteFilter: (filterKey: string) => void;
}

const filterStore = (set: SetState<FilterStoreState>): FilterStoreState => {
  const searchBy = new URLSearchParams(window.location.search).get(PARAMS_KEYS.SEARCH) || null;
  return {
    searchBy,
    filters: [],
    filterFields: {},
    organizationSpecificFilterFields: {},
    setSearchBy: (searchBy: string) => {
      return set((state) => {
        state.searchBy = searchBy;
      });
    },
    setFilters: (filters: Array<UserPreference>) => {
      return set((state) => {
        const parsedFilters = filters
          .filter((preference) => preference.family === UserPreferenceFamily.FILTER)
          .map((filter) => {
            try {
              return { ...filter, value: JSON.parse(filter.value) };
            } catch (error) {
              console.error('Error parsing filter value', error);
              return { ...filter, value: null };
            }
          });
        const { organizationSpecificFields, ...parsedFilterFields } = Object.fromEntries(
          parsedFilters
            .filter((filter) => filter.key !== StudyFilterName.search)
            .map((filter) => [filter.key, filter.value]),
        );

        state.filters = parsedFilters;
        state.filterFields = parsedFilterFields;
        state.organizationSpecificFilterFields =
          Object.keys(organizationSpecificFields ?? {}).length > 0
            ? {
                [StudyFilterName.rightPossession]:
                  organizationSpecificFields?.[OrganizationSpecificFilterAPIKeys.rightPossession],
              }
            : {};
      });
    },
    deleteFilter: (filterKey: string) => {
      return set((state) => {
        if (!filterKey.trim()) return state;
        state.filters = state.filters.filter((filter) => filter.key !== filterKey);
        delete state.filterFields[filterKey];
        delete state.organizationSpecificFilterFields[filterKey];
      });
    },
  };
};

export const useFilterStore = create<FilterStoreState>(immer(filterStore));
