import { useState, useEffect, useCallback, useMemo } from 'react';
import shallow from 'zustand/shallow';
import type { StudyType } from '@eva-pacs/client';
import { useStudyListPageLazyQuery } from '@eva-pacs/client';

import {
  getAssignablePractitionersVariables,
  getAssignablePractitionersWithReferingPhysicianVariables,
} from '~/src/utils/practitionersApolloVariables';
import { useFilterStore } from '~/src/store/filterStore';
import { FeatureFlags } from '~/src/constants/featureFlags';
import { useFeatureFlagsStore } from '~/src/store/flagsStore';
import { PAGE_SIZE, PAGE_SIZE_INFINITE_SCROLL } from '~/src/constants';

import { useStudyListStore } from '../../store';
import { studyDTO } from '../../dtos/study/studyDTO';
import { useStudyActions } from '../study/useStudyActions';

interface StudyDataLoaderProps {
  isVisible?: boolean;
}

export const useStudyList = ({ isVisible }: StudyDataLoaderProps) => {
  const { performReset } = useStudyActions();
  const [hasNextPage, setHasNextPage] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const searchBy = useFilterStore((state) => state.searchBy);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const hasFlag = useFeatureFlagsStore((state) => state.hasFlag);
  const [
    tableSortBy,
    addStudies,
    setTotalCount,
    currentPage,
    setCurrentPage,
    reverse,
    setReverse,
    setEnableGoToTop,
    setEnableGoToBottom,
    enabledInfiniteScroll,
  ] = useStudyListStore(
    (state) => [
      state.tableSortBy,
      state.addStudies,
      state.setTotalCount,
      state.currentPage,
      state.setCurrentPage,
      state.reverse,
      state.setReverse,
      state.setEnableGoToTop,
      state.setEnableGoToBottom,
      state.enabledInfiniteScroll,
    ],
    shallow,
  );

  const includeAssignableReferingPhysician = hasFlag(FeatureFlags.IncludeReferringAssigned);
  const pageSize = useMemo(() => (enabledInfiniteScroll ? PAGE_SIZE_INFINITE_SCROLL : PAGE_SIZE), [
    enabledInfiniteScroll,
  ]);

  const assignablePractitionersVariables = useMemo(
    () =>
      includeAssignableReferingPhysician
        ? getAssignablePractitionersWithReferingPhysicianVariables()
        : getAssignablePractitionersVariables(),
    [includeAssignableReferingPhysician],
  );

  const queryVariables = useMemo(
    () => ({
      limit: pageSize,
      search: searchBy,
      ordering: tableSortBy,
      ...assignablePractitionersVariables,
      offset: (currentPage - 1) * pageSize,
    }),
    [assignablePractitionersVariables, currentPage, pageSize, searchBy, tableSortBy],
  );

  const [getStudyList, { loading }] = useStudyListPageLazyQuery({
    variables: queryVariables,
    fetchPolicy: 'no-cache',
    onCompleted: ({ studies }) => {
      if (!studies || !studies.results) return;
      setInitialLoading(false);
      setTotalCount(studies.totalCount ?? 0);
      setHasNextPage(studies.pageInfo?.hasNextPage || false);
      setHasPreviousPage(studies.pageInfo?.hasPreviousPage || false);
      addStudies(studies.results.map((study) => studyDTO(study as StudyType)));
    },
  });

  const handleReset = () => {
    setInitialLoading(true);
    performReset();
    setReverse(false);
    setCurrentPage(1);
    setHasNextPage(false);
    setEnableGoToTop(false);
    setHasPreviousPage(false);
    setEnableGoToBottom(true);
  };

  const handleSetCurrentPage = useCallback(
    (page: number, scrollToPage = false) => {
      if (scrollToPage) {
        setInitialLoading(true);
        performReset();
        setCurrentPage(page);
        getStudyList();
      } else if (page !== currentPage) {
        performReset();
        setCurrentPage(page);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage],
  );

  const reloadData = () => {
    handleReset();
    getStudyList();
  };

  useEffect(() => {
    getStudyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isVisible && enabledInfiniteScroll) {
      reverse ? setCurrentPage(currentPage - 1) : setCurrentPage(currentPage + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, enabledInfiniteScroll, reverse]);

  return {
    loading,
    pageSize,
    reloadData,
    hasNextPage,
    handleReset,
    hasPreviousPage,
    getStudyList,
    initialLoading,
    handleSetCurrentPage,
  };
};
