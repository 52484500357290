var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b;
import { ApolloClient, InMemoryCache, from, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { MainAuthRepository } from '@eva-tech/auth-infra-main';
import { getCookie, getOrganizationIdFromStorage } from '@eva-pacs/core/lib/utils/storage';
import { ENCRYPTED_CREDENTIALS_PARAM, LOGIN_PATH, I18N_LANGUAGE_KEY, VIEWER_PATH } from '@eva-pacs/core/lib/constants';
import { WebSocketLink } from '@apollo/client/link/ws';
import { RetryLink } from '@apollo/client/link/retry';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { GlobalError, useGlobalErrorStore } from '@eva-pacs/app-pacs/src/store/useGlobalErrorStore';
import { UploadLink } from './upload';
import { fromHttpToWebsocketURL, navigateTo, removeAuthCookies } from './utils';
import { getCurrentTokenItem, refreshToken } from '@eva-tech/auth-domain';
var WS_IS_ACTIVE = process.env.REACT_APP_WS_IS_ACTIVE === 'true';
var API_URL = ((_a = process.env.REACT_APP_API) !== null && _a !== void 0 ? _a : 'https://develop.dev-land.space');
var webSocketURL = "".concat(fromHttpToWebsocketURL((_b = process.env.REACT_APP_GRAPHQL_WS_URL) !== null && _b !== void 0 ? _b : API_URL), "/graphql/");
export var authRepository = new MainAuthRepository("".concat(API_URL, "/graphql/"), process.env.REACT_APP_ROOT_DOMAIN);
var errorLink = onError(function (_a) {
    var operation = _a.operation, graphQLErrors = _a.graphQLErrors, networkError = _a.networkError;
    var setToastError = useGlobalErrorStore.getState().setToastError;
    if (graphQLErrors && operation)
        graphQLErrors.forEach(function (error) {
            var message = error.message, locations = error.locations, path = error.path;
            console.log("[GraphQL error]: Message: ".concat(message, ", Location: ").concat(locations, ", Path: ").concat(path));
            switch (message) {
                case 'Visitant does not have permission to perform this action.':
                    removeAuthCookies();
                    navigateTo(LOGIN_PATH);
                    break;
                case 'You do not have permission to perform this action':
                    removeAuthCookies();
                    navigateTo(LOGIN_PATH);
                    break;
                case 'Expired token.':
                    removeAuthCookies();
                    navigateTo(LOGIN_PATH);
                    break;
                case 'Access denied! You need to be authorized to perform this action!':
                    removeAuthCookies();
                    navigateTo(LOGIN_PATH);
                    break;
                default:
                    console.log('Unhandled error');
                    break;
            }
        });
    if (networkError) {
        var context = operation.getContext();
        if (context.retryCount === 2) {
            setToastError(GlobalError.GLOBAL_ERROR_TIMEOUT);
            console.log("[Timeout error]: ".concat(networkError));
        }
        console.log("[Network error]: ".concat(networkError));
    }
});
var retryLink = new RetryLink({
    delay: { initial: 1000 },
    attempts: function (count, operation, error) {
        var maxAttempts = 2;
        operation.setContext(function (_a) {
            var _b = _a.retryCount, retryCount = _b === void 0 ? 1 : _b;
            return ({
                retryCount: retryCount + 1,
            });
        });
        return !!error && count < maxAttempts;
    },
});
var uploadLink = new UploadLink({
    uri: "".concat(API_URL, "/graphql/"),
});
var wsLink = new WebSocketLink(new SubscriptionClient(webSocketURL, {
    reconnect: true,
    connectionParams: function () { return __awaiter(void 0, void 0, void 0, function () {
        var token, refresh;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    token = (_a = getCurrentTokenItem(authRepository)) === null || _a === void 0 ? void 0 : _a.access;
                    refresh = (_b = getCurrentTokenItem(authRepository)) === null || _b === void 0 ? void 0 : _b.refresh;
                    if (!(!token && refresh)) return [3 /*break*/, 2];
                    return [4 /*yield*/, refreshToken(authRepository)];
                case 1:
                    _d.sent();
                    token = (_c = getCurrentTokenItem(authRepository)) === null || _c === void 0 ? void 0 : _c.access;
                    _d.label = 2;
                case 2: return [2 /*return*/, __assign({}, (token && {
                        Authorization: "JWT ".concat(token),
                    }))];
            }
        });
    }); },
}));
var authLink = setContext(function (_, _a) {
    var _b = _a.headers, headers = _b === void 0 ? {} : _b;
    return __awaiter(void 0, void 0, void 0, function () {
        var finalHeaders;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, getHeaders(headers)];
                case 1:
                    finalHeaders = _c.sent();
                    return [2 /*return*/, { headers: finalHeaders }];
            }
        });
    });
});
/**
 * Return the headers to the context so httpLink can read them
 */
var getHeaders = function (headers) {
    if (headers === void 0) { headers = {}; }
    return __awaiter(void 0, void 0, void 0, function () {
        var token, refresh, acceptLanguage, searchParams, mutableHeaders, finalHeaders, isViewer, studyId, visitantToken;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    token = (_a = getCurrentTokenItem(authRepository)) === null || _a === void 0 ? void 0 : _a.access;
                    refresh = (_b = getCurrentTokenItem(authRepository)) === null || _b === void 0 ? void 0 : _b.refresh;
                    if (!(!token && refresh)) return [3 /*break*/, 2];
                    return [4 /*yield*/, refreshToken(authRepository)];
                case 1:
                    _e.sent();
                    token = (_c = getCurrentTokenItem(authRepository)) === null || _c === void 0 ? void 0 : _c.access;
                    _e.label = 2;
                case 2:
                    acceptLanguage = getCookie(I18N_LANGUAGE_KEY);
                    searchParams = new URLSearchParams(window.location.search);
                    mutableHeaders = new Headers();
                    finalHeaders = {};
                    isViewer = window.location.pathname.includes(VIEWER_PATH);
                    studyId = (_d = window.location.pathname.split(VIEWER_PATH)[1]) === null || _d === void 0 ? void 0 : _d.replace('/', '');
                    visitantToken = searchParams.get(ENCRYPTED_CREDENTIALS_PARAM);
                    Object.entries(headers).forEach(function (_a) {
                        var key = _a[0], value = _a[1];
                        return mutableHeaders.set(key, "".concat(value));
                    });
                    if (token) {
                        mutableHeaders.set('Authorization', "JWT ".concat(token));
                        mutableHeaders.set('Organization-Id', getOrganizationIdFromStorage());
                    }
                    if (visitantToken)
                        mutableHeaders.set('Credentials', visitantToken);
                    if (isViewer)
                        mutableHeaders.set('Resource', studyId);
                    if (acceptLanguage)
                        mutableHeaders.set('Accept-Language', acceptLanguage);
                    mutableHeaders.forEach(function (value, key) { return (finalHeaders[key] = value); });
                    return [2 /*return*/, finalHeaders];
            }
        });
    });
};
var httpLink = from([authLink, retryLink, errorLink, uploadLink]);
var fullLink = split(function (_a) {
    var query = _a.query;
    var definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
}, wsLink, httpLink);
export var apolloClient = new ApolloClient({
    connectToDevTools: true,
    link: WS_IS_ACTIVE ? fullLink : httpLink,
    cache: new InMemoryCache(),
});
