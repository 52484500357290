import { SeriesThumbnailsWithMetaType } from '~/utils/thumbnails';

/**
 * Finds the most suitable series for a given protocol based on a target variant.
 *
 * This function sorts the provided series array to prioritize the one that matches
 * the specified `targetSerieVariant`. If multiple series share the target variant,
 * it further prioritizes by the number of instance IDs, selecting the series with
 * the lower count.
 *
 * @param {Array<SeriesThumbnailsWithMetaType>} series -
 *   An array of series objects containing metadata and thumbnails for the studies.
 * @param {string} targetSerieVariant -
 *   The desired series variant to match against the `meta.metaSerieVariant` property
 *   of each series.
 *
 * @returns {SeriesThumbnailsWithMetaType | undefined}
 *   The series that best matches the target variant, or `undefined` if the input array
 *   is empty.
 *
 * Sorting Logic:
 * 1. Series matching the `targetSerieVariant` are given priority.
 * 2. If multiple series match, they are sorted by the number of instance IDs in
 *    ascending order.
 * 3. The first series in the sorted array is returned.
 *
 * @example
 * // Example usage:
 * const series = [
 *   { meta: { metaSerieVariant: 'variantA', instanceIds: ['https://image1.dcm', 'https://image2.dcm'] } },
 *   { meta: { metaSerieVariant: 'variantB', instanceIds: ['https://image3.dcm'] } },
 *   { meta: { metaSerieVariant: 'variantA', instanceIds: ['https://image4.dcm', 'https://image5.dcm', 'https://image6.dcm'] } },
 * ];
 * const result = findSerieForProtocol(series, 'variantA');
 * // result will be the series with 'variantA' and the less instance IDs.
 */

export const findSerieForProtocol = (series: Array<SeriesThumbnailsWithMetaType>, targetSerieVariant: string) => {
  const sortedArray = series
    .filter((serie) => serie.meta?.metaSerieVariant === targetSerieVariant)
    .sort((aSerie, bSerie) => {
      return (aSerie.meta?.instanceIds?.length ?? 0) - (bSerie.meta?.instanceIds?.length ?? 0);
    });
  return sortedArray[0];
};
