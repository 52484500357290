import * as yup from 'yup';
import { FieldTypes, MAX_PHONE_LENGTH, MIN_PHONE_LENGTH } from '../constants';
var CUSTOM_EMAIL_VALIDATION = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export var validateEmail = function (email) {
    if (email === void 0) { email = ''; }
    if (email === '')
        return true;
    return CUSTOM_EMAIL_VALIDATION.test(email);
};
// FIELDS
export var getBooleanField = function (t, isRequired) {
    if (isRequired === void 0) { isRequired = false; }
    var field = yup.boolean();
    if (isRequired)
        field = field.oneOf([true], t('errors.formFields.required'));
    return field;
};
export var getEmailField = function (t, isRequired, customValidation) {
    if (isRequired === void 0) { isRequired = false; }
    if (customValidation === void 0) { customValidation = false; }
    var field = getTextField(t, isRequired);
    field = customValidation
        ? field.test('emailFormat', t('errors.formFields.email'), function (value) { return validateEmail(value); })
        : field.email(t('errors.formFields.email'));
    if (isRequired)
        field = field.required(t('errors.formFields.required'));
    return field.trim();
};
export var getFileField = function (t, isRequired, fileSize, supportedFormats) {
    if (isRequired === void 0) { isRequired = false; }
    if (fileSize === void 0) { fileSize = 1024; }
    if (supportedFormats === void 0) { supportedFormats = []; }
    var field = yup.mixed();
    if ((supportedFormats === null || supportedFormats === void 0 ? void 0 : supportedFormats.length) > 0)
        field = field.test('fileType', 'Unsupported File Format', function (value) { return supportedFormats.includes(value.type); });
    if (fileSize)
        field = field.test('fileSize', 'File Size is too large', function (value) { return value.size <= fileSize; });
    if (isRequired)
        field = field.required(t('errors.formFields.required'));
    return field;
};
export var getMultipleField = function (t, isRequired) {
    if (isRequired === void 0) { isRequired = false; }
    var field = yup.array();
    if (isRequired)
        field = field.required(t('errors.formFields.required'));
    return field;
};
export var getNumberField = function (t, isRequired) {
    if (isRequired === void 0) { isRequired = false; }
    var field = yup.number();
    if (isRequired)
        field = field.required(t('errors.formFields.required'));
    return field;
};
export var getPasswordConfirmField = function (t) {
    return getTextField(t, true).oneOf([yup.ref('password1'), null], t('errors.formFields.confirmPassword'));
};
export var getPasswordField = function (t) {
    return getTextField(t, true)
        .test('specialCharacter', t('errors.formFields.specialCharacter'), function (value) {
        return /(?=.*[$@$!%*#?&.])/.test(value || '');
    })
        .test('minPasswordLength', t('errors.formFields.minPasswordLength'), function (value) {
        return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&.])[A-Za-z\d$@$!%*#?&.]{8,}$/.test(value || '');
    });
};
export var getPhoneField = function (t, isRequired) {
    if (isRequired === void 0) { isRequired = false; }
    return getTextField(t, isRequired).test('phoneFormat', t('errors.formFields.invalidPhoneNumber'), function (value) {
        if (value === '')
            return true;
        if (!value)
            return false;
        if ((value === null || value === void 0 ? void 0 : value.length) < MIN_PHONE_LENGTH || (value === null || value === void 0 ? void 0 : value.length) > MAX_PHONE_LENGTH)
            return false;
        return /^[0-9-/()\s]+$/g.test(value || '');
    });
};
export var getUUIDField = function (t, isRequired) {
    if (isRequired === void 0) { isRequired = false; }
    var field = yup.string().nullable().uuid();
    if (isRequired)
        field = field.required(t('errors.formFields.required'));
    return field;
};
export var getTextField = function (t, isRequired) {
    if (isRequired === void 0) { isRequired = false; }
    var field = yup.string();
    if (isRequired)
        field = field.required(t('errors.formFields.required'));
    return field;
};
export var getDateFormatField = function (t, isRequired) {
    if (isRequired === void 0) { isRequired = false; }
    return getTextField(t, isRequired).test('dateFormat', t('errors.formFields.date'), function (value) {
        if (!value || value === '')
            return true;
        return /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(value || '');
    });
};
export var getDateField = function (t, isRequired) {
    if (isRequired === void 0) { isRequired = false; }
    var field = yup.date().nullable();
    if (isRequired)
        field = field.required(t('errors.formFields.required'));
    return field;
};
export var getObjectField = function (t, isRequired) {
    if (isRequired === void 0) { isRequired = false; }
    return getTextField(t, isRequired).test('emptyObject', t('errors.formFields.required'), function (value) {
        return /^(?!.*{}).*$/.test(value || '');
    });
};
// FORMS
export var getDynamicSchema = function (t, listFields) {
    var fields = {};
    listFields.forEach(function (field) { return (fields[field.name] = getFieldControl(t, field)); });
    return yup.object().shape(fields);
};
export var getFieldControl = function (t, formField) {
    switch (formField.type) {
        case FieldTypes.CHECKBOX:
        case FieldTypes.RADIO:
        case FieldTypes.MULTIPLE_TEXT:
            return getObjectField(t, formField.isRequired);
        case FieldTypes.FILE_INPUT:
            return getFileField(t, formField.isRequired);
        case FieldTypes.NUMBER_INPUT:
            return getNumberField(t, formField.isRequired);
        case FieldTypes.SWITCH:
            return getBooleanField(t, formField.isRequired);
        default:
            return getTextField(t, formField.isRequired);
    }
};
export var isEmptyValue = function (value) {
    if (value === undefined || value === null)
        return true;
    if (typeof value === 'string')
        return value.trim() === '';
    if (Array.isArray(value))
        return value.length === 0;
    if (typeof value === 'object' && value.constructor === Object)
        return isEmptyForm(value);
    return false;
};
export var isEmptyForm = function (fields) {
    return Object.values(fields).every(isEmptyValue);
};
export var handleToggleChange = function (field, options, value) {
    if (!value) {
        field.onChange([]);
        return;
    }
    if (Array.isArray(value)) {
        var selectedOptions = options.filter(function (option) { return value.includes(option.value); });
        if (selectedOptions.length === 0) {
            field.onChange([]);
            return;
        }
        field.onChange(selectedOptions);
    }
    else {
        var selectedOption = options.find(function (option) { return option.value === value; });
        if (!selectedOption) {
            field.onChange(null);
            return;
        }
        field.onChange(selectedOption);
    }
};
export var handleSelectChange = function (field, options, value) {
    var selectedOption = options.find(function (option) { return option.value === value; });
    if (!selectedOption) {
        field.onChange(null);
        return;
    }
    field.onChange(selectedOption);
};
export var handleMultiSelectChange = function (field, selectedOptions) {
    return field.onChange(selectedOptions);
};
