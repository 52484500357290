import { getRefreshInfo } from '@eva-pacs/core/lib/utils';
import {
  logout as logoutService,
  refreshToken as refreshTokenService,
  setTokenItem as setTokenItemService,
  setVisitantMode,
} from '@eva-tech/auth-domain';
import type { AuthRepository, TokenItem } from '@eva-tech/auth-domain';

import React, { useCallback, useContext, useEffect } from 'react';

interface ContextState {
  logout: () => void;
  refreshToken: () => void;
  setTokenItem: (tokenItem: TokenItem) => void;
}

export const AuthContext = React.createContext({} as ContextState);

export const AuthContextProvider = ({
  children,
  authRepository,
}: React.PropsWithChildren<{ authRepository: AuthRepository }>) => {
  useEffect(() => {
    const { isVisitant } = getRefreshInfo();
    setVisitantMode(authRepository, isVisitant);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = useCallback(async () => logoutService(authRepository), [authRepository]);
  const refreshToken = useCallback(async () => refreshTokenService(authRepository), [authRepository]);
  const setTokenItem = useCallback(async (tokenItem) => setTokenItemService(authRepository, tokenItem), [
    authRepository,
  ]);
  return <AuthContext.Provider value={{ logout, setTokenItem, refreshToken }}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
