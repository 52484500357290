import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export enum PARAMS_KEYS {
  SEARCH = 'q',
  TABS = 'tab',
}

/**
 * This hook was taken from this website: https://brianm.me/posts/react-router-search-params/
 * @author Salvador Gonzalez <salvador.gonzalez@evacenter.com>
 * @returns Instance of URLSearchParams with search parameters.
 */
export function useSearchParams() {
  const { search } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(search);
    return Object.freeze(params);
  }, [search]);
}
