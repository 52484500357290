import { authRepository, UserType } from '@eva-pacs/client';
import create, { GetState, SetState } from 'zustand';
import produce from 'immer';

import { SCREEN_PREFERENCES_SELECTED_ID_LOCAL_STORAGE_KEY } from '~/utils/screenPreferences';

import { ORGANIZATION_ID_KEY, setCookie, removeCookie, VISITANT_KEY } from '@eva-pacs/core';
import { getCurrentTokenItem } from '@eva-tech/auth-domain';

const immer = (config) => (set, get) => config((fn) => set(produce(fn)), get);

/**
 * Session Store
 */
export interface Account {
  organizationId: string;
  organizationName: string;
  practitionerId: string;
  userInfo: {
    fullName: string;
    name: string;
    lastName: string;
    phone: string;
    phoneCode: string;
    gender: string;
  };
  hasPendingPayment: boolean;
}

export interface SessionStore {
  user: UserType | null;
  accounts: Array<Account>;
  currentOrganizationId: string;
  selectedScreenPreferenceId: string;
  setUser: (user: UserType | null) => void;
  setAccounts: (accounts: Array<Account>) => void;
  setCurrentOrganizationId: (organizationId: string, visitant?: boolean) => void;
  setSelectedScreenPreferenceId: (id: string) => void;
  isAuthenticated: () => boolean;
  getCurrentAccount: () => Account;
}

const cookieConfig = { path: '/', domain: process.env['REACT_APP_ROOT_DOMAIN'] };

const sessionStore = (set: SetState<SessionStore>, get: GetState<SessionStore>): SessionStore => ({
  currentOrganizationId: '',
  selectedScreenPreferenceId: '',
  user: null,
  accounts: [],
  setAccounts: (accounts) => {
    return set((state) => {
      state.accounts = [...accounts];
    });
  },
  setCurrentOrganizationId: (organizationId, visitant = false) => {
    return set((state) => {
      state.currentOrganizationId = organizationId;
      const visitantOrganizationId = `${VISITANT_KEY}_${ORGANIZATION_ID_KEY}`;

      if (!organizationId) {
        removeCookie(ORGANIZATION_ID_KEY);
        removeCookie(visitantOrganizationId);
        return;
      }
      if (visitant) setCookie(visitantOrganizationId, organizationId, cookieConfig);
      else setCookie(ORGANIZATION_ID_KEY, organizationId, cookieConfig);
    });
  },
  setSelectedScreenPreferenceId: (id: string) =>
    set((state) => {
      state.selectedScreenPreferenceId = id;
      localStorage.setItem(SCREEN_PREFERENCES_SELECTED_ID_LOCAL_STORAGE_KEY, id);
    }),
  setUser: (user) => {
    return set((state) => {
      state.user = user ? Object.assign({}, user) : null;
    });
  },
  isAuthenticated: () => Boolean(getCurrentTokenItem(authRepository)?.refresh),
  getCurrentAccount: () => get().accounts.find((account) => account.organizationId === get().currentOrganizationId)!,
});

export const useSessionStore = create<SessionStore>(immer(sessionStore));
