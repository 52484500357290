import { SeriesThumbnailsType } from '@eva-pacs/client';
import { splitMetaSeries, isASplittableSerie } from './splitSeries';

export const splitSeriesAndAddMetadata = (originalSeriesList: Array<SeriesThumbnailsType>) => {
  const seriesList = [...originalSeriesList];
  const splittableSeries = seriesList.filter((serie) => isASplittableSerie(serie));
  const metaSeries = splittableSeries.map(splitMetaSeries);
  splittableSeries.forEach((serie, serieIndex) => {
    const seriePosition = seriesList.findIndex((commonSerie) => commonSerie.id === serie.id);
    const seriesInfo = metaSeries[serieIndex];
    // Remove the original serie and add the new metas series in original position
    const newMetaSeries = seriesInfo.map((metaSerieInfo, index) => {
      const thumbnail = index === 0 ? seriesList[seriePosition]?.thumbnail : '';
      const metaSerieValues = metaSerieInfo;
      // Set in metada that is a subserie
      const isASubSerie = seriesInfo.length > 1; //if there is only one serie, manage it as a normal serie
      metaSerieValues.meta.isASubSerie = isASubSerie;
      const serieWithMetaData = {
        ...seriesList[seriePosition],
        ...metaSerieInfo,
        thumbnail,
      };

      return serieWithMetaData;
    });
    seriesList.splice(seriePosition, 1, ...newMetaSeries);
  });
  return seriesList;
};
